import React from 'react';

import { ProductOption } from './productOption';

export const ProductForm = ({ product, activeVariant, onVariantChange }) => {
  // If no product or optioins bail out
  if (!product?.options?.length) return null;

  return (
    <div className="mx-auto px-gutter flex-col flex gap-y-6">
      {product.options.map(
        (option, key) =>
          option.values?.length && (
            <ProductOption
              key={key}
              position={key}
              option={option}
              optionSettings={product.optionSettings}
              variants={product.variants}
              activeVariant={activeVariant}
              onChange={onVariantChange}
            />
          )
      )}
    </div>
  );
};
