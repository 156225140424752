import React from 'react';
import cx from 'classnames';

export const SizeRadio = ({ label, isActive }) => {
  const classes = cx({
    'border-4 border-formery-dark-blue flex items-center justify-center w-12 h-12 outline-none focus:outline-none rounded-full text-base font-display-med': true,
    'bg-formery-dark-blue text-white transition-colors duration-200': isActive,
    'bg-transparent text-formery-dark-blue': !isActive,
  });
  return <div className={classes}>{label}</div>;
};
