import React from 'react';
import cx from 'classnames';

import { hasObject } from '@lib/helpers';
import RadioGroup from '@components/ui/radio-group';
import RadioItem from '@components/ui/radio-item';
import { SizeRadio } from '@components/ui/sizeRadio';
import { ThumbSelector } from '@components/ui/thumbSelector';

export const ProductOption = ({
  option,
  optionSettings,
  position,
  variants,
  activeVariant,
  strictMatch = true,
  hideLabels,
  onChange,
}) => {
  const otherOpts = [
    ...activeVariant.options.slice(0, position),
    ...activeVariant.options.slice(position + 1),
  ];

  return (
    <div key={position}>
      {/* <h4 className="font-display-med text-base">{option.name}</h4> */}
      <RadioGroup
        value={activeVariant.options.find((opt) => opt.name === option.name).value}
        onChange={(value) => {
          changeOption(option.name, value, variants, activeVariant, onChange);
        }}
        className="flex gap-x-4 justify-center"
      >
        {option.values.map((value, key) => {
          const currentOpt = [{ name: option.name, value: value }];

          const optSettings = optionSettings?.find((settings) => {
            const optName = settings.forOption.split(':')[0];
            const optValue = settings.forOption.split(':')[1];
            return optName === option.name && optValue === value;
          });

          const isActive = activeVariant.options.some(
            (opt) => opt.position === option.position && opt.value === value
          );

          const withActiveOptions = [...currentOpt, ...otherOpts];

          const hasVariants = variants.find((variant) =>
            variant.options.every((opt) => hasObject(withActiveOptions, opt))
          );

          const inStock = variants.find((variant) => {
            if (strictMatch) {
              return (
                variant.inStock && variant.options.every((opt) => hasObject(withActiveOptions, opt))
              );
            } else {
              return variant.inStock && variant.options.some((opt) => hasObject(currentOpt, opt));
            }
          });

          return (
            <RadioItem
              key={key}
              value={value}
              className={cx({
                'outline-none focus:outline-none': true,
                'is-active': isActive,
                'is-unavailable': !hasVariants,
                'is-soldout': !inStock && hasVariants && !isActive,
              })}
            >
              {optSettings?._type === 'productOptionSize' ? (
                <SizeRadio {...optSettings} isActive={isActive} />
              ) : optSettings?._type === 'productOptionImage' ? (
                <ThumbSelector {...optSettings} isActive={isActive} />
              ) : (
                <>{value}</>
              )}
            </RadioItem>
          );
        })}
      </RadioGroup>
    </div>
  );
};

// handle option changes
const changeOption = (name, value, variants, activeVariant, changeCallback) => {
  const newOptions = activeVariant.options.map((opt) =>
    opt.name === name ? { ...opt, value: value } : opt
  );

  const newVariant = variants.find((variant) =>
    variant.options.every((opt) => hasObject(newOptions, opt))
  );

  if (newVariant && changeCallback) {
    changeCallback(newVariant.id);
  }
};
