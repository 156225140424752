import React, { useState, useCallback, useEffect } from "react";
import cx from "classnames";
import AddIcon from "@svg/add.svg";
import RemoveIcon from "@svg/minus.svg";
import { clampRange } from "@lib/helpers";

export const ProductCounter = React.memo(
  ({ defaultCount = 1, onUpdate, max, className }) => {
    const [lineQuantity, setLineQuantity] = useState(defaultCount);

    const [, setDirection] = useState(1);

    const animateQuantity = useCallback((amount, direction) => {
      const count = max ? clampRange(amount, 1, max) : amount;

      // Bail if at edges
      if (count < 1 || count > max) return;

      setDirection(direction);
      setLineQuantity(count);

      if (onUpdate) {
        onUpdate(count);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateQuantity = useCallback((amount) => {
      const count = max ? clampRange(amount, 1, max) : amount;

      if (count < 1) return;

      setLineQuantity(count);

      if (onUpdate) {
        onUpdate(count);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      setLineQuantity(defaultCount);
    }, [defaultCount]);

    return (
      <div className={cx("counter flex items-center", className)}>
        <button
          aria-label="Decrease quantity by one"
          onClick={() => animateQuantity(lineQuantity - 1, -1)}
        >
          <RemoveIcon className="w-6" />
        </button>
        <div className="counter--amount">
          <input
            aria-label="Manually enter quantity"
            onChange={(e) =>
              updateQuantity(parseInt(e.currentTarget.value, 10))
            }
            onBlur={(e) => isNaN(lineQuantity) && updateQuantity(1)}
            type="number"
            inputMode="numeric"
            min="1"
            value={lineQuantity ? lineQuantity : ""}
            className="bg-transparent border-none text-center text-xl font-medium appearance-none input-reset"
          />
        </div>
        <button
          aria-label="Increase quantity by one"
          onClick={() => animateQuantity(lineQuantity + 1, 1)}
        >
          <AddIcon className="w-6" />
        </button>
      </div>
    );
  }
);
