import React, { useState } from "react";

import { ProductGallery } from "./productGallery";
import { ProductDetails } from "./productDetails";
import { ProductActions } from "./productActions";
import { ProductForm } from "./productForm";
import { ShippingEstimate } from "./shippingEstimate";
import { MobileCartAdd } from "./mobileCartAdd";

export const ProductHeader = ({
  product,
  activeVariant,
  onVariantChange,
  bgColor,
  trackingData,
}) => {
  const { displayPackSelector, packOptions } = product || {};

  const [quantity, setQuantity] = useState(1);
  const [packQty, setPackQty] = useState(
    displayPackSelector ? packOptions[0] : null
  );
  return (
    <>
      <div
        className="pt-header-spacer pb-24 grid grid-cols-14 gap-y-4 xl:pb-40"
        style={{ backgroundColor: bgColor && bgColor.hex }}
      >
        {/* Image Gallery */}
        <div className="col-start-2 col-end-14 lg:col-end-8">
          <ProductGallery
            photosets={product.galleryPhotos}
            activeVariant={activeVariant}
          />
        </div>
        <div className="col-start-2 col-end-14 flex flex-col items-center justify-center gap-y-4 lg:col-start-8 lg:col-end-13 xl:col-start-9">
          {/* Product Details */}
          <ProductDetails product={product} activeVariant={activeVariant} />
          {/* Product Form */}
          <ProductForm
            product={product}
            activeVariant={activeVariant}
            onVariantChange={onVariantChange}
          />
          {/* Product Actions */}
          <ProductActions
            displayPackSelector={displayPackSelector}
            packOptions={packOptions}
            activeVariant={activeVariant}
            quantity={quantity}
            setQuantity={setQuantity}
            packQty={packQty}
            setPackQty={setPackQty}
            trackingData={trackingData}
          />
          {/* Shipping Estimate */}
          <ShippingEstimate />
        </div>
      </div>
      <MobileCartAdd
        activeVariant={activeVariant}
        packQty={packQty}
        quantity={quantity}
        setQuantity={setQuantity}
        displayPackSelector={displayPackSelector}
        trackingData={trackingData}
      />
    </>
  );
};
