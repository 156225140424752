import React, { useState } from "react";

import { motion } from "framer-motion";

import { PortableTextBlock } from "@components/sanity/portableTextBlock";
import { PageLink } from "@components/ui/links";

import { defaultTransition } from "@lib/animation";

const vars = {
  closed: {
    height: 0,
    opacity: 0,
  },
  expanded: {
    height: "auto",
    opacity: 1,
  },
};
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

const Description = ({ text }) => {
  const [expanded, setExpanaded] = useState(false);
  const toggle = () => setExpanaded((prev) => !prev);
  const firstPara = text[0];
  const extraText = text.slice(1);

  return (
    <div>
      <PortableTextBlock text={[firstPara]} />
      {extraText.length > 0 && (
        <div>
          <motion.div
            variants={vars}
            animate={expanded ? "expanded" : "closed"}
            initial="closed"
            className="overflow-hidden"
            transition={{ ...defaultTransition }}
          >
            <PortableTextBlock text={extraText} />
          </motion.div>
          <span
            className="font-semi-bold underline cursor-pointer"
            onClick={toggle}
          >
            {expanded ? "Read Less" : "Read More"}
          </span>
        </div>
      )}
    </div>
  );
};

export const ProductDetails = ({ product }) => {
  return (
    <div className="text-center">
      {product.hasBrand && product.brand && (
        <PageLink
          {...product.brand}
          className="blockH5 font-medium hover:underline"
        />
      )}
      <h1 className="blockH1 font-medium mb-half">{product.title}</h1>
      {product.productDetail && (
        <h4 className="text-sm font-medium md:text-base mb-half">
          {product.productDetail}
        </h4>
      )}
      {product.description && <Description text={product.description} />}
    </div>
  );
};
