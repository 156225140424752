import React, { useState, useEffect, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import cx from "classnames";
import { SanityImage } from "@components/sanity/sanityImage";
import { hasObject } from "@lib/helpers";

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

const GalleryImage = ({ image }) => {
  return (
    <div className="aspect-w-1 aspect-h-1 relative w-full flex-shrink-0">
      <SanityImage
        image={image}
        className="absolute w-full h-full object-cover"
      />
    </div>
  );
};

const GalleryThumb = ({ image, selectedIndex, index, ...rest }) => {
  return (
    <div
      className={cx(
        "w-14 border border-black rounded-lg overflow-hidden transition-all",
        {
          "opacity-50": selectedIndex !== index,
        }
      )}
      {...rest}
    >
      <div className="aspect-w-1 aspect-h-1">
        <SanityImage
          image={image}
          className="absolute w-full h-full object-cover"
        />
      </div>
    </div>
  );
};

export const GalleryCarousel = ({ photos }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    skipSnaps: false,
    align: "start",
    containScroll: true,
  });
  const [scrollSnaps, setScrollSnaps] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla]
  );

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  return (
    <div className="w-full md:pr-16 overflow-hidden xl:pl-16">
      {/* Carousel */}
      <div ref={viewportRef} className="overflow-hidden max-w-xl mx-auto">
        <div className="flex">
          {photos.map((item, index) => (
            <GalleryImage image={item} key={index} />
          ))}
        </div>
      </div>
      {/* Nav */}
      {scrollSnaps.length > 1 && (
        <>
          <nav className="flex gap-x-2 justify-center py-4 xl:hidden">
            {scrollSnaps.map((_, index) => (
              <div
                key={index}
                onClick={() => scrollTo(index)}
                className={cx("pg-btn", {
                  selected: index === selectedIndex,
                })}
              />
            ))}
          </nav>
          <nav className="hidden gap-y-4 absolute transform top-1/2 -translate-y-1/2 left-0 xl:flex xl:flex-col">
            {photos.map((item, index) => (
              <GalleryThumb
                image={item}
                selectedIndex={selectedIndex}
                index={index}
                onClick={() => scrollTo(index)}
              />
            ))}
          </nav>
        </>
      )}
    </div>
  );
};

export const ProductGallery = ({ photosets, activeVariant }) => {
  if (!photosets?.length || !activeVariant) return null;
  // 1. extract the active variant's options
  const { options } = activeVariant;

  // 2. find the default photoset from the "forOption" field (default is blank)
  const defaultPhotoset = photosets.find((set) => !set.forOption);

  // 3. find the first photoset that matches one of the variants options
  const variantPhotoset = photosets.find((set) => {
    const option = set.forOption
      ? {
          name: set.forOption.split(":")[0],
          value: set.forOption.split(":")[1],
        }
      : {};
    return option.value && hasObject(options, option);
  });

  // 4. finally, pick our set of photos
  const photos = variantPhotoset
    ? variantPhotoset?.photos
    : defaultPhotoset?.photos;

  return (
    <div className="overflow-hidden relative">
      <GalleryCarousel photos={photos} />
    </div>
  );
};
