import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { useLocation } from "@reach/router";
import useSWR from "swr";
import axios from "axios";
import { blockContentToPlainText } from "react-portable-text";
import ReactGA from "react-ga4";

// Hooks & Helpers
import { QueryFragments } from "../GraphQl/queryFragments"; // eslint-disable-line
import { useTracking } from "@lib/tracking";

import { hasObject, centsToPrice } from "@lib/helpers";

// Components
import ModuleZone from "~moduleZone";
import { ProductHeader } from "@components/product/productHeader";
import Layout from "@components/layout";
import { PageMeta } from "@components/pageMeta";

function getProductSchema(product, activeVariantID) {
  if (!product) return null;

  const variant = product.variants.find((v) => v.id === activeVariantID);

  return {
    "@context": "http://schema.org",
    "@type": "Product",
    name: product.title,
    image: product.bottleShot && product.bottleShot.asset.url,
    description: product.description
      ? blockContentToPlainText(product.description)
      : null,
    sku: product.sku,
    brand: {
      "@type": "Brand",
      name:
        product.hasBrand && product.brand
          ? product.brand.linkText
          : "Premium Liquor",
    },
    offers: {
      "@type": "Offer",
      url: `https://www.premiumliquor.co.nz/products/${
        product.slug && product.slug.current
      }`,
      availability: `http://schema.org/${
        variant.inStock ? "InStock" : "SoldOut"
      }`,
      price: centsToPrice(variant.price),
      priceCurrency: "NZD",
    },
  };
}

// setup our inventory fetcher
const fetchInventory = (url, id) =>
  axios
    .post(url, {
      id: id,
    })
    .then((res) => res.data);

const ProductTemplate = ({ data: { page, variants } }) => {
  const { track, trackViewedItem } = useTracking();
  const { href } = useLocation();

  // set our Product state
  const {
    productModules,
    referencedModules,
    seo,
    bgColor,
    bottleShot,
    collections,
    ...productProps
  } = page;

  const [product, setProduct] = useState({
    ...productProps,
    bottleShot,
    variants: [...variants.nodes],
  });
  // find the default variant for this product by matching against the first product option
  const defaultVariant = variants.nodes?.find((v) => {
    const option = {
      name: product.options?.[0]?.name,
      value: product.options?.[0]?.values[0],
      position: product.options?.[0]?.position,
    };
    return hasObject(v.options, option);
  });

  const [activeVariant, setActiveVariant] = useState(
    defaultVariant?.id || variants.nodes[0].id
  );

  // Check our product inventory is still correct
  const { data: productInventory } = useSWR(
    ["/.netlify/functions/product-inventory", product.productID],
    (url, id) => fetchInventory(url, id),
    { errorRetryCount: 3 }
  );

  // Rehydrate our product after inventory is fetched
  useEffect(() => {
    if (page.product && productInventory) {
      setProduct({
        ...page.product,
        inStock: productInventory.inStock,
        lowStock: productInventory.lowStock,
        variants: [
          ...page.product.variants.map((v) => {
            const newInventory = productInventory.variants.find(
              (nv) => nv.id === v.id
            );
            return newInventory ? { ...v, ...newInventory } : v;
          }),
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.product, productInventory]);

  const trackingData = {
    ProductName: page.title,
    ProductID: page.productID,
    SKU: page.sku,
    ImageURL: bottleShot && bottleShot.asset && bottleShot.asset.url,
    URL: href,
    Price: centsToPrice(page.price),
    priceAsNumber: parseFloat(
      centsToPrice(
        page.price ||
          product.variants.find((v) => v.id === activeVariant)?.price
      )
    ),
    CompareAtPrice: centsToPrice(page.comparePrice),
    Categories: collections.map((item) => item.title),
  };

  // Tracking
  useEffect(() => {
    track("Viewed Product", trackingData);
    trackViewedItem({
      Title: trackingData.Name,
      ItemId: trackingData.ProductID,
      ImageUrl: trackingData.ImageURL,
      Url: trackingData.URL,
      Categories: trackingData.Categories,
      Metadata: {
        Price: trackingData.Price,
        CompareAtPrice: trackingData.CompareAtPrice,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=2055976796666542603-EU&client_type=gtag#view_item
    ReactGA.gtag("event", "view_item", {
      currency: "NZD",
      value: trackingData.priceAsNumber,
      items: [
        {
          item_id: trackingData.SKU,
          item_name: trackingData.ProductName,
          index: 0,
          price: trackingData.priceAsNumber,
          quantity: 1,
        },
      ],
    });
  }, []);

  return (
    <Layout isProduct>
      <PageMeta
        {...seo}
        metaFallback={{
          metaTitle: page.title,
          metaDesc:
            page.description && blockContentToPlainText(page.description),
          shareGraphic: page.bottleShot,
        }}
        schema={getProductSchema(product, activeVariant)}
      />
      <ProductHeader
        product={product}
        activeVariant={product.variants.find((v) => v.id === activeVariant)}
        onVariantChange={setActiveVariant}
        bgColor={bgColor}
        trackingData={trackingData}
      />
      {productModules && <ModuleZone {...productModules} />}
      {referencedModules && referencedModules.modules && !productModules && (
        <ModuleZone {...referencedModules.modules} />
      )}
    </Layout>
  );
};

export default ProductTemplate;

export const pageQuery = graphql`
  query productQuery($slug: String!, $productID: Float!) {
    page: sanityProduct(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      slug {
        current
      }
      productID
      title
      vendor
      lowStock
      inStock
      price
      comparePrice
      tags
      sku
      productType
      productDetail
      hasBrand
      brand {
        ...PageLink
      }
      bgColor {
        hex
      }
      collections {
        title
      }
      description: _rawDescription(resolveReferences: { maxDepth: 10 })
      galleryPhotos {
        forOption
        photos {
          ...ImageWithPreview
          alt
        }
      }
      bottleShot {
        asset {
          url
        }
      }
      options {
        _key
        name
        position
        values
      }
      displayPackSelector
      packOptions {
        qty
        label
      }
      optionSettings {
        ... on SanityProductOptionSize {
          _type
          forOption
          label
        }
        ... on SanityProductOptionImage {
          _type
          forOption
          thumbImage {
            ...ImageWithPreview
          }
        }
      }
      referencedModules: modules {
        modules {
          ...PageModules
        }
      }
      productModules: specialModules {
        ...PageModules
      }
    }
    variants: allSanityProductVariant(
      filter: { productID: { eq: $productID }, wasDeleted: { eq: false } }
    ) {
      nodes {
        id: variantID
        options {
          name
          value
          position
        }
        price
        comparePrice
        inStock
        lowStock
        sku
        variantID
      }
    }
  }
`;
