import React from "react";
import { ProductAdd } from "./productAdd";
import { ProductPrice } from "./productPrice";
import { ProductCounter } from "./productCounter";
import { PackSelector } from "./packSelector";

import { centsToPrice } from "@lib/helpers";

export const ProductActions = ({
  activeVariant,
  quantity,
  setQuantity,
  packQty,
  setPackQty,
  packOptions,
  displayPackSelector,
  trackingData,
}) => {
  return (
    <div className="mx-auto flex flex-col justify-center items-center">
      <div className="text-xl font-medium py-3 text-center md:text-2xl">
        {activeVariant && (
          <>
            <ProductPrice
              {...activeVariant}
              packQty={packQty}
              displayPackSelector={displayPackSelector}
            />
          </>
        )}
      </div>
      {activeVariant?.inStock ? (
        <>
          {displayPackSelector && (
            <PackSelector
              packOptions={packOptions}
              packQty={packQty}
              setPackQty={setPackQty}
            />
          )}
          <ProductCounter
            id={activeVariant?.id}
            max={10}
            onUpdate={setQuantity}
            className="border-b-2 border-black mb-6"
          />
          <div className="hidden md:block">
            <ProductAdd
              productID={activeVariant?.id}
              quantity={
                displayPackSelector ? quantity * packQty?.qty : quantity
              }
              trackingData={trackingData}
              className={"btn w-full text-center justify-center"}
            >
              <span className="flex gap-x-2">Add to Bag</span>
            </ProductAdd>
          </div>
        </>
      ) : (
        <div className="btn cursor-not-allowed hidden md:block">
          Out of stock
        </div>
      )}
    </div>
  );
};
