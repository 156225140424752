import React, { useState } from 'react';

import ShippingIcon from '@svg/shipping.svg';
import CloseIcon from '@svg/close.svg';

import { useGlobalMessages } from '@querys/useGlobalMessages';
import { PortableTextBlock } from '@components/sanity/portableTextBlock';
import PortalModal from '@components/ui/portalModal';

export const ShippingEstimate = () => {
  const { shippingInformation, shippingUsp } = useGlobalMessages();
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="text-center font-display-med">
      <div className="flex justify-center w-full items-center gap-x-2">
        <ShippingIcon className="w-8" /> {shippingUsp}
      </div>
      <button className="blockH6 underline font-medium mt-half" onClick={() => setOpen(true)}>
        Shipping &amp; Returns
      </button>
      <PortalModal isShowing={isOpen}>
        <div className="bg-sand-900 rounded-xl w-full max-w-2xl mx-4 relative p-4 lg:p-6 mt-10 md:mx-auto self-start">
          <button onClick={() => setOpen(false)} className="absolute top-4 right-4">
            <CloseIcon className="w-6 fill-current" />
          </button>
          <PortableTextBlock text={shippingInformation} />
        </div>
      </PortalModal>
    </div>
  );
};
