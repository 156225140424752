import React from 'react';
import cx from 'classnames';
import { SanityImage } from '@components/sanity/sanityImage';

export const ThumbSelector = ({ thumbImage, isActive }) => {
  const thumbClasses = cx({
    'w-20': true,
    'opacity-1': isActive,
    'opacity-50': !isActive,
  });

  return (
    <div className={thumbClasses}>
      <div className="aspect-w-1 aspect-h-1 overflow-hidden relative rounded-xl">
        {thumbImage && (
          <SanityImage
            image={thumbImage}
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
        )}
      </div>
    </div>
  );
};
