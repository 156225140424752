import React from "react";

import { ProductPrice } from "./productPrice";
import { ProductAdd } from "./productAdd";

export const MobileCartAdd = ({
  activeVariant,
  quantity,
  displayPackSelector,
  packQty,
  trackingData,
}) => {
  return (
    <div className="fixed bottom-6 left-0 w-full px-nav-gutter z-20 md:hidden">
      {activeVariant?.inStock ? (
        <>
          <ProductAdd
            productID={activeVariant.id}
            quantity={displayPackSelector ? quantity * packQty?.qty : quantity}
            trackingData={trackingData}
            className="btn-solid large w-full text-center justify-center gap-x-2"
          >
            <ProductPrice
              {...activeVariant}
              displayPackSelector={displayPackSelector}
              packQty={packQty}
            />
            <span>-</span>
            <span className="flex gap-x-2">Add to Bag</span>
          </ProductAdd>
        </>
      ) : (
        <div className="btn-solid large w-full text-center justify-center gap-x-2">
          <ProductPrice
            {...activeVariant}
            displayPackSelector={displayPackSelector}
            packQty={packQty}
          />{" "}
          Out of stock
        </div>
      )}
    </div>
  );
};
