import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import cx from "classnames";
import ChevronD from "@svg/chevron-d.svg";

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions */

export const PackSelector = ({ packOptions, packQty, setPackQty }) => {
  const [isOpen, setOpen] = useState(false);

  const toggle = () => setOpen((prev) => !prev);

  const onSelect = (val) => {
    setPackQty(val);
    setOpen(false);
  };

  return (
    <div className="w-full relative my-4">
      <h4
        className="btn items-center w-full justify-between cursor-pointer"
        onClick={toggle}
      >
        {packQty?.label}{" "}
        <div className="pt-1 ml-4">
          <ChevronD className="w-2 h-2 fill-current" />
        </div>
      </h4>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="bg-white absolute w-full p-4 transform translate-y-2 border border-black grid gap-y-2 rounded-md"
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 10 }}
            exit={{ opacity: 0, y: 40 }}
          >
            {packOptions.map((item) => (
              <div
                onClick={() => onSelect(item)}
                className="flex justify-between items-center cursor-pointer"
              >
                {item.label}{" "}
                <span
                  className={cx("w-3 h-3 border border-black rounded-full", {
                    "bg-black": packQty?.qty === item.qty,
                  })}
                />
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
