import React from "react";
import cx from "classnames";

import ReactGA from "react-ga4";
import { useAddItemToCart, useStore } from "@lib/context";

export const ProductAdd = ({
  productID,
  quantity = 1,
  className,
  trackingData,
  children,
}) => {
  const addToCart = useAddItemToCart();
  const { isAdding } = useStore();

  function handleAddToCart() {
    addToCart(productID, quantity);

    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=2055976796666542603-EU&client_type=gtag#parameters_4
    ReactGA.gtag("event", "add_to_cart", {
      currency: "NZD",
      value: trackingData.priceAsNumber * quantity,
      items: [
        {
          item_id: trackingData.SKU,
          item_name: trackingData.ProductName,
          index: 0,
          price: trackingData.priceAsNumber,
          quantity,
        },
      ],
    });
  }

  return (
    <>
      <button
        className={cx(className, { "is-adding": isAdding })}
        onClick={() => handleAddToCart()}
      >
        {isAdding ? "Adding..." : <>{children ? children : "Add to Bag"}</>}
      </button>
    </>
  );
};
